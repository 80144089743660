<template>
    <v-container class="fill-height" max-width="600px">
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
                <v-card class="elevation-10">
                    <v-toolbar
                        color="grey"
                        dark
                        flat
                        dense
                    >
                        <v-toolbar-title>Redirecting to Login</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="text-center">
                        Please wait while we redirect you to the login page
                        <v-progress-linear
                            indeterminate
                            color="grey lighten-2"
                            class="mt-3"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios';

import { onMounted } from '@vue/composition-api';

export default {
    setup(_, { root }) {

        onMounted(async () => {
            const next = root.$route.query.next || '/';

            let response = await axios.get(`/saml2/jwt/?next=${next}`);

            if (!response.data.auth) {
                window.location.href = response.data.loginUrl;
            } else {
                root.$store.dispatch('tryAutoLogin');
                root.$router.push(next);
            }
        });

        return {
        };
    }
}
</script>