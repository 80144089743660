import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height",attrs:{"max-width":"600px"}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"4"}},[_c(VCard,{staticClass:"elevation-10"},[_c(VToolbar,{attrs:{"color":"grey","dark":"","flat":"","dense":""}},[_c(VToolbarTitle,[_vm._v("Redirecting to Login")])],1),_c(VCardText,{staticClass:"text-center"},[_vm._v(" Please wait while we redirect you to the login page "),_c(VProgressLinear,{staticClass:"mt-3",attrs:{"indeterminate":"","color":"grey lighten-2"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }